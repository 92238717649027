import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
// import AliceLogo from "images/companies/Alice-Logo.svg";
// import DanoneLogo from 'images/companies/Danone-Logo.png'
// import CreditasLogo from 'images/companies/Greenhouse-Logo.png'
// import LeroyMerlinLogo from 'images/companies/Leroy-Merlin-Logo.png'
// import VivestLogo from 'images/companies/Vivest-Logo.png'
// import { DecorationBlob } from '../misc/DecorationBlob'
import "slick-carousel/slick/slick.css";
import LineDescription from "components/texts/LineDescription.js";
import Carrousel from "components/carrousel/index.js";
import { DecorationBlob } from "components/misc/DecorationBlob.js";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const HeadingInfoContainer = tw.div`flex flex-col items-start`;

const CompaniesSliderContainer = tw.div`mt-16`;

const ImageContainer = styled.div`
  ${tw`!flex flex-1 items-center justify-center md:mx-3 lg:mx-6 w-2/3 h-24 md:w-4/12 mx-auto rounded `}
`

const ImageSpan = tw.div`p-16 flex items-center justify-center`

const Image = tw.img`w-4/5 max-w-xs`

const DecoratorBlob1 = styled(DecorationBlob)`
  ${tw`pointer-events-none z-20 overflow-hidden absolute left-0 bottom-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
`;
// const DecoratorBlob2 = styled(DecorationBlob)`
//   ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-yellow-500`}
// `;

const CarrouselList = (props) => {
  /*
   * You can modify the companies shown by modifying the array below
   * You can add or remove objects from the array as you need.
   */
  const companies = [
    {
      imageSrc: 'https://s3.amazonaws.com/images.medipreco.com.br/logos/logo-alice.svg',
      alt: 'Alice Logo'
    },
    {
      imageSrc: 'https://s3.amazonaws.com/images.medipreco.com.br/logos/site/log-danone.png',
      alt: 'Danone Logo'
    },
    {
      imageSrc: 'https://s3.amazonaws.com/images.medipreco.com.br/logos/site/logo-greenhouse.png',
      alt: 'Creditas Logo'
    },
    {
      imageSrc: 'https://s3.amazonaws.com/images.medipreco.com.br/logos/site/logo-leroy-merlin.png',
      alt: 'Leroy Merlin Logo'
    },
    {
      imageSrc: 'https://s3.amazonaws.com/images.medipreco.com.br/logos/site/log-vivest.png',
      alt: 'Vivest Logo'
    },
    {
      imageSrc: 'https://s3.amazonaws.com/images.medipreco.com.br/logos/site/logo-cemig.png',
      alt: 'Cemig Logo'
    },
    {
      imageSrc: 'https://s3.amazonaws.com/images.medipreco.com.br/logos/site/logo-sami.png',
      alt: 'Sami Logo'
    },
    {
      imageSrc : 'https://s3.amazonaws.com/images.medipreco.com.br/new-custom-logos/hypera-pharma.svg',
      alt: 'Hypera-Pharma Logo'
    },
    {
      imageSrc: 'https://s3.amazonaws.com/images.medipreco.com.br/logos/companies/logo_svg_cp_246.svg',
      alt: 'Caju Logo'
    },
    {
      imageSrc: 'https://s3.amazonaws.com/images.medipreco.com.br/logos/companies/logo_svg_cp_238_l6zwkzxv9rngk5hvmvp.svg',
      alt: 'Zup Logo'
    },
    // {
    //   imageSrc: 'https://s3.amazonaws.com/images.medipreco.com.br/new-custom-logos/odilon.svg',
    //   alt: 'Odilon Logo'
    // },
    {
      imageSrc: 'https://s3.amazonaws.com/images.medipreco.com.br/logos/logo_clique_retire.svg',
      alt: 'Clique Retire Logo'
    },
    // {
    //   imageSrc: 'https://s3.amazonaws.com/images.medipreco.com.br/new-custom-logos/iesb.svg',
    //   alt: 'Iesb Logo'
    // },
    // {
    //   imageSrc: 'https://s3.amazonaws.com/images.medipreco.com.br/logos/logo_ciplan.svg',
    //   alt: 'Ciplan Logo'
    // },
    // {
    //   imageSrc: 'https://upload.wikimedia.org/wikipedia/pt/2/2a/Logotipo_da_RecordTV_Bras%C3%ADlia.png',
    //   alt: 'Record Logo'
    // },
    {
      imageSrc: 'https://s3.amazonaws.com/images.medipreco.com.br/logos/companies/logo_svg_cp_250.svg',
      alt: 'Solvi Logo'
    },
    {
      imageSrc: 'https://s3.amazonaws.com/images.medipreco.com.br/logos/site/logo-santander-red.png',
      alt: 'Santander Logo'
    },
    {
      imageSrc: 'https://s3.amazonaws.com/images.medipreco.com.br/logos/site/logo-ache.png',
      alt: 'Ache Logo'
    },
    {
      imageSrc: 'https://s3.amazonaws.com/images.medipreco.com.br/logos/site/logo-vr.png',
      alt: 'VR Logo'
    },
    {
      imageSrc: 'https://s3.amazonaws.com/images.medipreco.com.br/logos/site/logo-volkswagen.png',
      alt: 'Volks Logo'
    },
    {
      imageSrc: 'https://s3.amazonaws.com/images.medipreco.com.br/logos/site/logo-arcelormittal.png',
      alt: 'ArcelorMittal Logo'
    },
    {
      imageSrc: 'https://s3.amazonaws.com/images.medipreco.com.br/logos/site/logo-duracell-2.png',
      alt: 'Duracell Logo'
    },
  ];

  return (
      <Container>
        <Content>
          <HeadingInfoContainer>
            <HeadingTitle>{props.title}</HeadingTitle>
            <LineDescription>{props.subTitle}</LineDescription>
          </HeadingInfoContainer>
          <CompaniesSliderContainer>
            <Carrousel>
              {companies.map((company, index) => (
                <ImageContainer key={company.alt}>
                  <ImageSpan key={company.alt}>
                    <Image src={company.imageSrc} alt={company.alt}/>
                  </ImageSpan>
                </ImageContainer>
              ))}
            </Carrousel>
          </CompaniesSliderContainer>
        </Content>

        <DecoratorBlob1 />
        {/* <DecoratorBlob2 /> */}
      </Container>
  );
};

export default CarrouselList;
